html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-family: "Roboto", sans-serif !important;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Services {
  flex: 1;
}

.Services h1,
p {
  text-align: center;
}

.Services .navbar {
  background-color: lightgray;
  filter: invert(1);
}

.content {
  min-height: 90vh;
}

.Services .card {
  margin: auto;
  margin-top: 35px;
}

.Services .service-img {
  height: unset !important;
  height: 100% !important;
  object-fit: cover;
}

.Services .card .card-header {
  color: black;
  background-color: lightgray;
  filter: invert(1);
}

.Services .service-descriptions {
  text-align: left;
}

.Services .card h3,
h5 {
  text-align: center;
}

.Services li {
  text-align: center;
}

.Services .book {
  text-align: center;
  margin-top: 20px;
  padding-top: 55px;
  padding-bottom: 35px;
}

.Services .book .shape {
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 35px;
  transform: rotate(45deg);
  text-align: center;
  width: 350px;
  height: 350px;
  background-color: #19bc9c;
}

.Services .book .shape .inner-shape {
  transform: rotate(-45deg);
  width: 350px;
  height: 350px;
  display: table-cell;
  vertical-align: middle;
}

.Services .book img {
  filter: invert(1);
}

.Services .book h2 {
  margin-top: 25px;
  color: white;
  font-weight: bolder;
  font-size: 2.5rem;
}

.Services .book .btn {
  color: #19bc9c;
  background-color: white;
  border-color: white;
}

@media only screen and (max-width: 767px) {
  .add-services {
    width: 18rem !important;
    margin: auto;
  }
}
