html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-family: "Roboto", sans-serif !important;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  font-weight: 300;
  line-height: 23px;
  font-family: "Poppins", sans-serif;
}

ul {
  list-style-type: none;
}

a,
a:hover {
  text-decoration: none;
}

body {
  font-family: "Montserrat", sans-serif;
}

.Testimonials .navbar {
  background-color: lightgray;
  filter: invert(1);
}

.Testimonials h1 {
  text-align: center;
  margin-top: 0 !important;
  margin-bottom: 2rem;
}

.Testimonials .content {
  background: #f1f5f8;
  padding: 2rem 1rem 3rem 1rem;
  margin: 0 auto;
}

.Testimonials .testimonial {
  background: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
}

.testimonial > img {
  height: 12rem;
  width: 12rem;
  object-fit: cover;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 50%;
}

.testimonial .personal-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.testimonial .personal-info > * {
  text-align: center;
  margin: 0.4rem 0;
}

.Testimonials .review-block {
  width: 80%;
  min-height: 10rem;
  max-height: fit-content;
}

.Testimonials .review {
  text-align: left;
}
