html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-family: "Roboto", sans-serif !important;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.About .navbar {
  background-color: lightgray;
  filter: invert(1);
}

.About {
  flex: 1;
}

.About h1 {
  margin-top: 5% !important;
}

.about-lyric,
#swip-swap-a,
#swip-swap-b,
#swip-swap-c {
  margin: 0 auto;
}

#swip-swap-a {
  order: 1;
}

#swip-swap-b {
  order: 2;
  justify-items: center;
}

#swip-swap-c {
  order: 3;
  justify-items: center;
  margin-top: 15px;
}

#swip-swap-c .btn {
  margin: 30px;
}

.content {
  min-height: 90vh;
}

.About h1 {
  text-align: center;
}

.About p {
  text-align: left;
}

@media only screen and (max-width: 767px) {
  #swip-swap-a {
    order: 2;
  }

  #swip-swap-b {
    order: 1;
  }

  #swip-swap-c {
    order: 3;
    justify-items: center;
  }
}
