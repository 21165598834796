html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-family: "Roboto", sans-serif !important;
}

#root {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
}

#root h1 {
  margin-top: 3%;
}

.Home {
  position: relative;
}

.Home .navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background: rgb(0 0 0 / 25%);
  padding: 0;
}

.Home .nav-item a {
  color: #fff;
  font-weight: bold;
}

.Home .nav-item a:link {
  color: #fff;
  font-weight: bold;
}

.Home .nav-item a:visited {
  color: #fff;
  font-weight: bold;
}

.Home .nav-item a:hover {
  color: rgb(148, 144, 144);
  font-weight: bold;
}

.Home .nav-item a:active {
  color: #fff;
  font-weight: bold;
}

.Home .navbar-nav .nav-link .active {
  color: #fff;
  font-weight: bold;
}

.Home .navbar-nav .nav-link.active:hover {
  color: rgb(148, 144, 144);
}

.Home .logo {
  max-width: 75px;
}

.Home .carousel-item img {
  height: 85vh;
  object-fit: cover;
}

.Home .intro-content-body {
  max-width: 75%;
}

.content {
  margin-bottom: 35px;
}

.btn.btn-lg {
  padding: 10px 40px;
}
.btn.btn-hero,
.btn.btn-hero:hover,
.btn.btn-hero:focus {
  color: #1abc9c;
  background-color: white;
  border-color: #1abc9c;
  outline: none;
  margin: 20px 10px;
}

.footer {
  background-color: lightgrey;
  filter: invert(1);
}

@media only screen and (max-width: 767px) {
  #root h1 {
    margin-top: 25px;
  }
  .Home .intro-content-body p {
    text-align: left;
    margin-top: 20px;
  }
}
