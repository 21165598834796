html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-family: "Roboto", sans-serif !important;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh !important;
}

.Gallery {
  flex: 1;
}

.Gallery .navbar {
  background-color: lightgray;
  filter: invert(1);
}

.Gallery .content {
  min-height: unset;
  max-width: 75%;
  text-align: center;
}

.square-image-container {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
}

.square-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Gallery .square-image:hover {
  cursor: pointer;
}

.Gallery .site-content {
  flex: 1;
}

.Gallery footer {
  margin-top: auto;
}

@media only screen and (max-width: 767px) {
  .Gallery .content p {
    text-align: left;
  }
}

@media (max-width: 900px) {
  .desktop-only-br {
    display: none;
  }
}
