.ContactForm .container {
  width: 45vw;
}

.ContactForm form button {
  margin-top: 1.5rem;
  background-color: #202040;
  color: white;
  border-color: #202040;
}

@media only screen and (max-width: 991px) {
  .ContactForm .container {
    width: 100%;
  }
}
