body {
  font-family: "Roboto", sans-serif !important;
}

.navbar {
  position: sticky;
  background: #fff;
  padding: 0;
}

.nav-item a {
  color: #000;
  font-weight: bold;
}

.nav-item a:link {
  color: #000;
  font-weight: bold;
}

.nav-item a:visited {
  color: #000;
  font-weight: bold;
}

.nav-item a:hover {
  color: rgb(105, 102, 102);
  font-weight: bold;
}

.nav-item a:active {
  color: #000;
  font-weight: bold;
}

.navbar-nav .nav-link .active {
  color: #000;
  font-weight: bold;
}

.navbar-nav .nav-link.active:hover {
  color: rgb(105, 102, 102);
}

.Home .logo {
  filter: invert(1);
}

.logo {
  max-width: 75px;
}
