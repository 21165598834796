.Contact {
  position: relative;
  height: 100vh;
}

.Contact .navbar {
  background-color: lightgray;
  filter: invert(1);
}

.Contact .container {
  padding-bottom: 2.5rem;
}

.Contact h1 {
  text-align: center;
  font-size: xxx-large;
}

.Contact p {
  font-family: "Hind Madurai", sans-serif;
  margin-bottom: 0;
}

.Contact .contact-header {
  margin: 4rem 4rem 0 4rem;
}

.Contact .bio-img {
  max-width: 70%;
  margin-top: 6rem;
}

.Contact .secondary-contact {
  margin-top: 1rem;
}

.Contact .secondary-contact p {
  margin-bottom: 0.5rem;
}

.Contact h5 {
  font-family: "Lora", serif;
}

.Contact .Footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

@media only screen and (max-width: 991px) {
  .Contact .bio-img-col {
    display: flex;
    justify-content: center;
  }

  .Contact .bio-img {
    order: 1;
    margin-top: 3rem;
  }

  .Contact .primary-contact {
    order: 2;
    margin: 0 auto;
  }

  .Contact .contact-header {
    margin: 0;
    padding-left: 5%;
    padding-right: 5%;
  }

  .Contact h1 {
    margin-top: 2rem;
  }
}
