.Carousel h1 {
  font-size: 8vw;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #080d15;
  opacity: 0.4;
}

.hero {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.75);
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  -o-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.logo-overlay {
  filter: invert(1);
  max-width: 330px;
  max-height: 300px;
}

.hero h2 {
  font-size: 9vw;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.hero h4 {
  font-size: 4vw;
}

.btn.btn-lg {
  padding: 10px 40px;
}
.btn.btn-hero,
.btn.btn-hero:hover,
.btn.btn-hero:focus {
  color: #f5f5f5;
  background-color: #1abc9c;
  border-color: #1abc9c;
  outline: none;
  margin: 20px 10px;
}

@media only screen and (min-width: 1200px) {
  .hero h1 {
    font-size: 5vw;
  }

  .hero h2 {
    font-size: 6rem;
  }
}
